var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready)?_c('div',{staticClass:"lahan-gis-verification"},[_c('div',{staticClass:"d-flex flex-row mb-3",staticStyle:{"justify-content":"space-between"}},[_c('h5',[_vm._v("Verifikasi Data")]),_c('button',{on:{"click":function($event){return _vm.$emit('close', true)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]),_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Polygon Lahan',
            validation: ['required'],
            type: 'upload',
            setter: 'polygon_from_gis',
            view_data: 'polygon_from_gis',
            api: 'lahans/upload.php',
            upload_type: '.kml',
            directory: 'polygon-ff',
            option: {
              icon: 'mdi-vector-polygon',
              label_hint:
                'Klik polygon untuk memilih file .kml yang akan diunggah',
            },
          }},model:{value:(_vm.formData.polygon_from_gis),callback:function ($$v) {_vm.$set(_vm.formData, "polygon_from_gis", $$v)},expression:"formData.polygon_from_gis"}})],1):_vm._e(),(_vm.role == 'gis' && _vm.isCarbonProject)?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Luas Lahan Polygon (m)',
            type: 'text',
            validation: ['required', 'decimal'],
          }},model:{value:(_vm.formData.gis_polygon_area),callback:function ($$v) {_vm.$set(_vm.formData, "gis_polygon_area", $$v)},expression:"formData.gis_polygon_area"}}),(_vm.formData.gis_polygon_area)?_c('div',{staticClass:"d-flex flex-row px-3 py-2 mt-2",staticStyle:{"background-color":"#e1f0ff","color":"#1f4f94","border-radius":"8px"}},[_c('v-icon',{staticClass:"text-primary"},[_vm._v("mdi-information")]),_c('span',{staticClass:"text-08-em pl-2"},[_vm._v("Luas lahan polygon yang dihitung sistem kemungkinan berbeda 5 - 10m.")])],1):_vm._e()],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Luas Lahan SPPT (m)',
            type: 'number',
            validation: ['required'],
          }},model:{value:(_vm.formData.land_area),callback:function ($$v) {_vm.$set(_vm.formData, "land_area", $$v)},expression:"formData.land_area"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Elevasi (mdpl)',
            type: 'text',
            validation: ['required'],
          }},model:{value:(_vm.formData.elevation),callback:function ($$v) {_vm.$set(_vm.formData, "elevation", $$v)},expression:"formData.elevation"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kelerengan Lahan',
            type: 'select',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.slope,

              default_label: _vm.formData.kelerengan_lahan_label,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.kelerengan_lahan),callback:function ($$v) {_vm.$set(_vm.formData, "kelerengan_lahan", $$v)},expression:"formData.kelerengan_lahan"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('v-row',{staticClass:"tutupan-wrapper pb-5"},[_c('v-col',{attrs:{"lg":"12"}},[_c('h4',{staticClass:"mb-2 text-success"},[_vm._v("Tutupan")]),_c('h4',{staticClass:"font-weight-normal"},[_vm._v(" Tutupan Lahan : "),_c('span',{staticClass:"text-success font-weight-bold"},[_vm._v(_vm._s(_vm.formData.tutupan_lahan || 0)+"%")])]),_c('h4',{staticClass:"font-weight-normal"},[_vm._v(" Area Tanam : "),_c('span',{staticClass:"text-success font-weight-bold"},[_vm._v(_vm._s(_vm.formData.planting_area || 0)+"% "),(_vm.formData.planting_area)?_c('span',{staticClass:"font-weight-normal"},[_vm._v("(~"+_vm._s((_vm.formData.planting_area / 100) * _vm.formData.land_area)+"m)")]):_vm._e()])])]),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Tutupan Pohon (%)',
                type: 'number',
                validation: ['required', 'max_value:100'],
              }},model:{value:(_vm.formData.tutupan_pohon_percentage),callback:function ($$v) {_vm.$set(_vm.formData, "tutupan_pohon_percentage", $$v)},expression:"formData.tutupan_pohon_percentage"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Tutupan Tanaman Bawah (%)',
                tooltip:
                  'Tutupan tanaman bawah seperti pisang, singkong, ubi jalar, cabai, jagung, sereh, pepaya, rumput gajah/rumput pakan ternak, bawang daun, terong, kol, tomat, jahe, kacang tanah, kentang, kapulaga dan sejenisnya',
                tooltip_width: '20em',
                type: 'number',
                validation: ['required', 'max_value:100'],
              }},model:{value:(_vm.formData.tutupan_tanaman_bawah_percentage),callback:function ($$v) {_vm.$set(_vm.formData, "tutupan_tanaman_bawah_percentage", $$v)},expression:"formData.tutupan_tanaman_bawah_percentage"}})],1),_c('v-col',{staticClass:"pb-5",attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Tutupan Bangunan / Lainnya (%)',
                tooltip:
                  'Tutupan bangunan atau lainnya seperti rumah, kolam atau sejenisnya',
                tooltip_width: '20em',
                type: 'number',
                validation: ['required', 'max_value:100'],
              }},model:{value:(_vm.formData.tutupan_lain_bangunan_percentage),callback:function ($$v) {_vm.$set(_vm.formData, "tutupan_lain_bangunan_percentage", $$v)},expression:"formData.tutupan_lain_bangunan_percentage"}})],1)],1)],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Foto Tutupan Pohon',
            validation:
              _vm.formData.tutupan_pohon_percentage &&
                _vm.formData.tutupan_pohon_percentage > 0 &&
                false
                ? ['required']
                : [],
            type: 'upload',
            api: 'lahans/upload.php',
            directory: 'foto-lahan',
            upload_type: 'image/*',
            setter: 'tutupan_pohon_photo',
            view_data: 'tutupan_pohon_photo',
            option: {
              label_hint: null,
              max_size: 5,
            },
          }},model:{value:(_vm.formData.tutupan_pohon_photo),callback:function ($$v) {_vm.$set(_vm.formData, "tutupan_pohon_photo", $$v)},expression:"formData.tutupan_pohon_photo"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Foto Tutupan Tanaman Bawah',
            validation:
              _vm.formData.tutupan_tanaman_bawah_percentage &&
                _vm.formData.tutupan_tanaman_bawah_percentage > 0 &&
                false
                ? ['required']
                : [],
            type: 'upload',
            api: 'lahans/upload.php',
            directory: 'foto-lahan',
            upload_type: 'image/*',
            setter: 'tutupan_tanaman_bawah_photo',
            view_data: 'tutupan_tanaman_bawah_photo',
            option: {
              label_hint:
                'Klik gambar untuk memilih berkas yang akan diunggah',
              max_size: 5,
            },
          }},model:{value:(_vm.formData.tutupan_tanaman_bawah_photo),callback:function ($$v) {_vm.$set(_vm.formData, "tutupan_tanaman_bawah_photo", $$v)},expression:"formData.tutupan_tanaman_bawah_photo"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Foto Tutupan Bangunan / Lainnya',
            validation:
              _vm.formData.tutupan_lain_bangunan_percentage &&
                _vm.formData.tutupan_lain_bangunan_percentage > 0 &&
                false
                ? ['required']
                : [],
            type: 'upload',
            api: 'lahans/upload.php',
            directory: 'foto-lahan',
            upload_type: 'image/*',
            setter: 'tutupan_lain_bangunan_photo',
            view_data: 'tutupan_lain_bangunan_photo',
            option: {
              label_hint:
                'Klik gambar untuk memilih berkas yang akan diunggah',
              max_size: 5,
            },
          }},model:{value:(_vm.formData.tutupan_lain_bangunan_photo),callback:function ($$v) {_vm.$set(_vm.formData, "tutupan_lain_bangunan_photo", $$v)},expression:"formData.tutupan_lain_bangunan_photo"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Akses ke Lahan',
            type: 'select',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.access_to_lahan,

              default_label: _vm.formData.access_to_lahan_label,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.access_to_lahan),callback:function ($$v) {_vm.$set(_vm.formData, "access_to_lahan", $$v)},expression:"formData.access_to_lahan"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Jarak Ke Lahan',
            type: 'select',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.lahan_distance,

              default_label: _vm.formData.jarak_lahan_label,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.jarak_lahan),callback:function ($$v) {_vm.$set(_vm.formData, "jarak_lahan", $$v)},expression:"formData.jarak_lahan"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Ketersediaan Air',
            type: 'select',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.water_availability_level,

              default_label: _vm.formData.water_availability_level_label,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.water_availability_level),callback:function ($$v) {_vm.$set(_vm.formData, "water_availability_level", $$v)},expression:"formData.water_availability_level"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Akses Air',
            type: 'select',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.water_availability,

              default_label: _vm.formData.water_availability_label,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.water_availability),callback:function ($$v) {_vm.$set(_vm.formData, "water_availability", $$v)},expression:"formData.water_availability"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Jarak Akses Air',
            type: 'select',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.access_to_water_sources,

              default_label: _vm.formData.access_to_water_sources_label,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.access_to_water_sources),callback:function ($$v) {_vm.$set(_vm.formData, "access_to_water_sources", $$v)},expression:"formData.access_to_water_sources"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Desa Terdekat',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.yes_no_option,

              default_label: _vm.formData.nearby_village_label,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.nearby_village),callback:function ($$v) {_vm.$set(_vm.formData, "nearby_village", $$v)},expression:"formData.nearby_village"}})],1):_vm._e(),(_vm.formData.nearby_village == 1 && _vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Jarak ke Desa Terdekat',
            type: 'number',
            validation: ['required'],
          }},model:{value:(_vm.formData.nearby_village_distance),callback:function ($$v) {_vm.$set(_vm.formData, "nearby_village_distance", $$v)},expression:"formData.nearby_village_distance"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Habitat Satwa/Kawasan Dilindungi Terdekat',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.yes_no_option,

              default_label: _vm.formData.animal_protected_habitat,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.animal_protected_habitat),callback:function ($$v) {_vm.$set(_vm.formData, "animal_protected_habitat", $$v)},expression:"formData.animal_protected_habitat"}})],1):_vm._e(),(_vm.formData.animal_protected_habitat == 1 && _vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Jarak ke Satwa/Kawasan Dilindungi Terdekat (m)',
            type: 'number',
            validation: ['required'],
          }},model:{value:(_vm.formData.animal_protected_habitat_distance),callback:function ($$v) {_vm.$set(_vm.formData, "animal_protected_habitat_distance", $$v)},expression:"formData.animal_protected_habitat_distance"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kejadian Banjir',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.yes_no_option,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.floods),callback:function ($$v) {_vm.$set(_vm.formData, "floods", $$v)},expression:"formData.floods"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kejadian Kebakaran',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.yes_no_option,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.wildfire),callback:function ($$v) {_vm.$set(_vm.formData, "wildfire", $$v)},expression:"formData.wildfire"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kejadian Longsor',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.yes_no_option,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.landslide),callback:function ($$v) {_vm.$set(_vm.formData, "landslide", $$v)},expression:"formData.landslide"}})],1):_vm._e(),(_vm.role == 'gis')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kejadian Kekeringan',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.yes_no_option,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.drought),callback:function ($$v) {_vm.$set(_vm.formData, "drought", $$v)},expression:"formData.drought"}})],1):_vm._e(),_vm._l((_vm.questions),function(question,i){return _c('v-col',{key:`quest-${i}`,attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: question.question + '?',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: _vm.defaultData.question_option,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData[`question_${question.id}`]),callback:function ($$v) {_vm.$set(_vm.formData, `question_${question.id}`, $$v)},expression:"formData[`question_${question.id}`]"}})],1)}),(_vm.role == 'um')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Verifikasi Jumlah Bibit',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: [
                {
                  label: 'Tidak',
                  code: '0',
                },
                {
                  label: 'Ya',
                  code: '1',
                },
              ],
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.seed_verify_status),callback:function ($$v) {_vm.$set(_vm.formData, "seed_verify_status", $$v)},expression:"formData.seed_verify_status"}})],1):_vm._e(),(_vm.role == 'um' && _vm.formData.seed_verify_status == 1)?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Eligibilitas Lahan',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: [
                {
                  label: 'Tidak Bisa Ikut',
                  code: '0',
                },
                {
                  label: 'Bisa Ikut Dengan Kondisi',
                  code: '1',
                },
                {
                  label: 'Bisa Ikut',
                  code: '2',
                },
              ],
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.eligible_status),callback:function ($$v) {_vm.$set(_vm.formData, "eligible_status", $$v)},expression:"formData.eligible_status"}})],1):_vm._e(),(_vm.role == 'fc')?_c('v-col',{attrs:{"lg":"12"}}):_vm._e(),(_vm.role == 'fc-non-carbon')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kelengkapan Data',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: [
                {
                  label: 'Lengkap',
                  code: '1',
                },
                {
                  label: 'Tidak Lengkap',
                  code: '0',
                },
              ],
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.fc_complete_data),callback:function ($$v) {_vm.$set(_vm.formData, "fc_complete_data", $$v)},expression:"formData.fc_complete_data"}})],1):_vm._e(),(_vm.role == 'fc-verif-data')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Kelengkapan Data',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: [
                {
                  label: 'Lengkap',
                  code: '1',
                },
                {
                  label: 'Tidak Lengkap',
                  code: '0',
                },
              ],
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.fc_complete_data),callback:function ($$v) {_vm.$set(_vm.formData, "fc_complete_data", $$v)},expression:"formData.fc_complete_data"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('v-btn',{staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"type":"submit","variant":"warning","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-pencil-outline")]),_c('span',[_vm._v("Verifikasi Data")])],1)],1)],2)],1)]}}],null,false,2663170561)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }